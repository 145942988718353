const footerNavBar = {
  'footerNavBar-1': '© Pandito GmbH CH-270.4.008.394-2',
  'footerNavBar-2': '+41 77 409 1740 panditoicecream@gmail.com',
  'footerNavBar-3': 'Schaffhauserrheinweg 118, Basilea. Horarios de apertura',
  'footerNavBar-3-tooltip-1': 'Glacé-Ape',
  'footerNavBar-3-tooltip-2': 'Pausa de invierno',
  'footerNavBar-4': 'Im Westfeld 4, Basilea. Horarios de apertura',
  'footerNavBar-4-tooltip-1': 'Heladería',
  'footerNavBar-4-tooltip-2': 'Solo durante enero',
  'footerNavBar-4-tooltip-3': 'Lun - Jue: Cerrado',
  'footerNavBar-4-tooltip-4': 'Vie - Dom: 12:00 - 18:00',
  'footerNavBar-5': 'Aviso legal / ',
  'footerNavBar-6': 'Política de privacidad',
  'footerNavBar-7': 'Diseñado por Fede'
}

export default footerNavBar
