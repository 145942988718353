import { useIntl } from 'react-intl'

export const useGetOpeningHours = () => {
  const intl = useIntl()

  const apeHoursHtml = () => `
    <ul id='tooltip-info'>
      <li style='font-weight: bold;'>${intl.formatMessage({ id: 'footerNavBar-3-tooltip-1' })}</li>
      <li>${intl.formatMessage({ id: 'footerNavBar-3-tooltip-2' })}</li>
    </ul>
  `

  const westfeldHoursHtml = () => `
    <ul id='tooltip-info'>
      <li style='font-weight: bold;'>${intl.formatMessage({ id: 'footerNavBar-4-tooltip-1' })}</li>
      <li>${intl.formatMessage({ id: 'footerNavBar-4-tooltip-2' })}</li>
      <li>${intl.formatMessage({ id: 'footerNavBar-4-tooltip-3' })}</li>
      <li>${intl.formatMessage({ id: 'footerNavBar-4-tooltip-4' })}</li>
    </ul>
  `

  const ApeHoursComponent = () => {
    return (
      <ul id='opening-hours-info'>
        <li className='opening-hours-title'>{intl.formatMessage({ id: 'footerNavBar-3-tooltip-1' })}</li>
        <li>{intl.formatMessage({ id: 'footerNavBar-3-tooltip-2' })}</li>
      </ul>
    )
  }

  const WestfeldHoursComponent = () => {
    return (
      <ul id='opening-hours-info'>
        <li className='opening-hours-title'>{intl.formatMessage({ id: 'footerNavBar-4-tooltip-1' })}</li>
        <li>{intl.formatMessage({ id: 'footerNavBar-4-tooltip-2' })}</li>
        <li>{intl.formatMessage({ id: 'footerNavBar-4-tooltip-3' })}</li>
        <li>{intl.formatMessage({ id: 'footerNavBar-4-tooltip-4' })}</li>
      </ul>
    )
  }

  return { apeHoursHtml, westfeldHoursHtml, ApeHoursComponent, WestfeldHoursComponent }
}
